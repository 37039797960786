export const numberHelperPlugin = {
  install(app, options) {
    app.config.globalProperties.$numeroPositivo = numeroPositivo
    app.config.globalProperties.$numeroNegativo = numeroNegativo
    app.config.globalProperties.$numeroZero = numeroZero
  }
}

const numeroPositivo = (numero) => numero > 0
const numeroNegativo = (numero) => numero < 0
const numeroZero = (numero) => numero === 0