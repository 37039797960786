import Ably from 'ably';

export const initializeAbly = function(userId) {

  if(typeof ablyKey ==='undefined') {
    console.error("Variáveis de ambiente não encotrada ou configuradas incorretamente")
    return
  }

  let ablyOptions = { key: ablyKey };
  let client = new Ably.Realtime(ablyOptions);
  let store = this.$store;

  client.connection.on('connected', function() {
    let userChannel = client.channels.get(userId);

    //Evento de excel de notas fiscais gerado
    userChannel.subscribe("xlsxMonitorFiscalGerado", (message) => {
      handleReportDownload(message.data, "downloadsRelatoriosNfs", "relatorioNfsLoading")
    });

    //Evento de excel de movimentação estoque gerado
    userChannel.subscribe("xlsxMovimentacaoEstoqueGerado", (message) => {
      setTipoDownload(message.data, 'detalhado')
    });

    //Evento de excel de movimentação estoque agrupado gerado
    userChannel.subscribe("xlsxMovimentacaoAgrupadaEstoqueGerado", (message) => {
      setTipoDownload(message.data, 'agrupado')
    });

    //Evento quando relatório está vazio
    userChannel.subscribe("relatorioMovimentacaoEstoqueVazio", (message) => {
      store.dispatch('addMsgRelatorioVazio', message.data)
    });

    //Evento de excel relatório contagens gerado
    userChannel.subscribe("xlsxContagensGerado", (message) => {
      handleReportDownload(message.data, "downloadsRelatorioContagens", "relatorioContagensLoading")
    });

    //Evento quando relatório contagem está vazio
    userChannel.subscribe("relatorioContagemVazio", (message) => {
      store.dispatch('addMsgRelatorioVazio', message.data)
    });

    //Evento de excel relatório integrações gerado
    userChannel.subscribe("xlsRelatorioIntegracaoGerado", (message) => {
      handleReportDownload(message.data, "downloadsIntegrationReports", "integrationsControlLoading")
    });

    //Evento quando relatório contagem está vazio
    userChannel.subscribe("relatorioIntegracaoVazio", (message) => {
      store.dispatch('addMsgRelatorioVazio', message.data)
    });

    //Evento de excel relatório produtos estoque gerado
    userChannel.subscribe("xlsxProdutosEstoqueGerado", (message) => {
      handleReportDownload(message.data, "downloadsRelatorioProdutosEstoque", "relatorioProdutosEstoqueLoading")
    });
  });

  function setTipoDownload(data, tipo){
    data.tipo = tipo
    handleReportDownload(data, "downloadsRelatorioEstoque", "relatorioEstoqueLoading")
  }
  function handleReportDownload(newDownload, downloadListName, loadingName) {
    store.dispatch("addDownload", { download: newDownload, downloadListName });
    store.dispatch("changeLoading", { loading: false, name: loadingName });
  }
}
