<template>
  <div class="custom-container-fluid text-center">
    <header class="mt-5">
      <h2>Bem vindo ao Mainô Reports</h2>
    </header>
    <main>
      <p>Estamos em fase de desenvolvimento</p>
    </main>
  </div>
</template>

<script>
export default {
  name: "DashboardView"
}
</script>

<style>

</style>

