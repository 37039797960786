export const setUser = (data) => {
  localStorage.setItem("~userData", JSON.stringify(data))
  return Promise.resolve(true)
}

export const getUser = () => {
  let userData = localStorage.getItem("~userData")
  try {
    userData = JSON.parse(userData)
  } catch(e) {
    throw Error("Houve um erro ao retornar os dados do usuário.")
  }

  return userData
}

export const getUserCompanyGroup = () => {
  let groupData = localStorage.getItem("~userData")
  try {
    groupData = JSON.parse(groupData).company_group
  } catch(e) {
    throw Error("Houve um erro ao retornar os dados do grupo.")
  }

  return groupData
}

export const mountCollectionEmitente = (collectionState, useMainoId=true) => {
  return getUserCompanyGroup().companies.map(company => {
    collectionState.push({
      label: `${company.nome_fantasia} - ${company.cnpj}`,
      value: useMainoId ? company.maino_id : company.id
    })
  })
}
