export const currentMonthPlugin = {
  install(app, options) {
    app.config.globalProperties.$currentMonthTime = currentMonthTime
    app.config.globalProperties.$currentMonth = currentMonth
  }
}

const currentMonthTime = () => {
  const today = new Date()
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth()
  const firstDayAtBeginning = [1, -3, 0]
  const lastDayAtEnd = [0, 20, 59]

  let data_inicio = new Date(currentYear, currentMonth, ...firstDayAtBeginning).toISOString().slice(0, 16)
  let data_fim = new Date(currentYear, currentMonth+1, ...lastDayAtEnd).toISOString().slice(0, 16)

  return { data_inicio, data_fim }
}


const currentMonth = () => {
  const today = new Date()
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth()

  let data_inicio = new Date(currentYear, currentMonth).toISOString().slice(0,10)
  let data_fim = new Date(currentYear, currentMonth+1, 0).toISOString().slice(0,10)

  return { data_inicio, data_fim }
}
