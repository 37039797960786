<template>
  <nav class="nav-classe">
    <ul class="nav-container">
      <li>
        <router-link class="navbar-item-container navbar-link logo" :to="{ name: 'Home' }">
          <img src="/images/logo_maino_branca.png" />
        </router-link>
      </li>
      <li v-for="(itemItem, k) in menu" :key="k" :class="{'dropdown': itemItem.submenu}">
        <router-link class="navbar-item-container navbar-link" :to="itemItem.to" :class="{active:itemItem.checkActive($route)}">
          <i class="nav-icon" :class="itemItem.icon"></i>
          <span>{{itemItem.label}}</span>
        </router-link>
        <ul v-if="itemItem.submenu" class="dropdown-menu">
          <li v-for="(itemSubMenu, sb) in itemItem.submenu" :key="sb">
            <router-link class="dropdown-item" :to="itemSubMenu.to" :class="{active:itemSubMenu.active}">
              {{itemSubMenu.label}}
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
  <div class="page-content">
    <template v-for="(relatorio, index) in relatorioGerado" :key="index">
      <flash-message
        :index="index"
        @on-close="()=>{ fechaAlerta(index) }"
        :open="relatorio.status"
        :message="relatorio.flashMessage"
        :type="relatorio.type"
      />
    </template>
    <dashboard-view v-if="$route.name ==='Home'"/>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import DashboardView from './DashboardView.vue';
import { Menu } from '@/services/menu'
export default {
  components: { DashboardView },
  name: "Home",
  data() {
    return {
      relatorioGerado: [],
      menu:Menu
    }
  },
  computed: {
    relatorioStatus() {
      return this.relatorioGerado.status
    },
  },

  methods: {
    fechaAlerta(indice){
      let relatorioGerado = this.relatorioGerado.filter((item, i)=> i!==indice)
      this.$nextTick(()=>{
        this.relatorioGerado = relatorioGerado
      })
    },
    trataMensagemRelatorios(tipoRelatorio, url) {
      let downloadLink = `<a href="${url}" >Download</a>`

      switch(tipoRelatorio) {
        case "downloadsRelatoriosNfs":
          return `Seu relatório de notas fiscais está pronto para ${downloadLink}`
        case "downloadsRelatorioEstoque":
          return `Seu relatório de movimentação de estoque está pronto para ${downloadLink}`
        case "downloadsRelatorioContagens":
          return `Seu relatório de contagens está pronto para ${downloadLink}`
        case "downloadsIntegrationReports":
          return `Seu relatório de Integrações está pronto para ${downloadLink}`
        default:
          return `Novo download disponível ${downloadLink}"`
      }
    }
  },
  mounted() {
    let userId = JSON.parse(window.localStorage.getItem("~userData")).maino_uuid
    this.$initAbly(userId);

    this.$store.subscribeAction((action, state)=>{
      let data = action.payload

      if(action.type === "addDownload") {
        this.relatorioGerado = [...this.relatorioGerado, ...[{
          status: true,
          flashMessage: this.trataMensagemRelatorios(data.downloadListName, data.download.url),
          type:"alert-success"
        }]]
      }

      if(action.type === "addMsgRelatorioVazio") {
        this.$nextTick(()=>{
          this.relatorioGerado =[...this.relatorioGerado, ...[{
            status: true,
            flashMessage: data || "Não foi possível encontrar nenhum valor para criar o relatório de estoque.",
            type:'alert-danger'
          }]]
        })
      }
    });

  }
}
</script>

<style lang="scss">
body {
  margin: 0;
}

.page-content {
  position: relative;
  z-index: 1;
}
</style>
