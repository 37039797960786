import axios from 'axios';

function interceptors() {
  const config = setting => setting
  const error = erro => {
    const invalidToken = "Token inválido ou expirado"
    const extractMessage = erro?.response?.data?.message || null
    const message = extractMessage && Array.isArray(extractMessage) ? extractMessage[0] : extractMessage

    if( invalidToken.match(message) ) {
      window.localStorage.removeItem("auth_token");
      window.localStorage.removeItem("~userData");
      window.postMessage("login-expirado")
    }
    return Promise.reject(erro.response)
  }

  return {config, error}
}

export const api = (_token=null, persistTimeout=null) => {
  const token = _token ? _token : window.localStorage.getItem("auth_token")

	const configAxios = axios.create({
		baseURL: urlApi,
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
      ... token ?  { 'Authorization' : `Bearer ${token}` } : {},
		},
    ... persistTimeout ? { timeout: 180000, } : {},
	})
  const { config, error } = interceptors()
  configAxios.interceptors.response.use(config, error)

	return configAxios
}