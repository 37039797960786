<template>
  <div class="app-content">
    <router-view ></router-view>
  </div>
</template>

<style lang="scss" scoped>
  .app-content {
    background: #efefef;
    padding-bottom: 30px;
    min-height: 100vh;
  }
</style>

