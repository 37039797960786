<template>
  <Teleport to="body">
    <section class="wrapper-flash-message" :style="positionTop" :class="{ show: open }">
      <div class="alert alert-dismissible" :class="className">
        <span v-html="customMessage"></span>
        <button class="btn-close" @click="closeFlash"></button>
      </div>
    </section>
  </Teleport>
</template>

<script>
export default {
  name: "FlashMessage",
  emits: ["on-close"],
  props: {
    type: { type: String, default: "" },
    message: { type: String, default: "" },
    open: { type: Boolean, default: false },
    timer: { type: Number, default: null },
    index: { type: Number, default: 0 },
  },

  methods: {
    closeFlash() {
      this.$emit('on-close', false)
    }
  },

  computed: {

    positionTop(){
      return {top: (this.index === 0 ? 25 : (this.index * 60) + 25) + 'px' }
    },

    className() {
      if( this.open && this.timer ) {
        setTimeout(() => {
          this.closeFlash();
        }, this.timer*1000);
      }
      return this.type
    },

    customMessage() {
      return this.message
    }
  },
}
</script>

<style lang="scss" scoped>
.wrapper-flash-message {
  position: fixed;
  z-index: 9;
  min-width: 200px;
  top: 25px;
  right: 25px;
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
  transform: translateX(50px);

  &.show {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50px);
  }

  >.alert{
    >.btn-close, >.btn-close:focus, >.btn-close:active {
      outline: none;
      box-shadow: none;
    }
  }
}
</style>
