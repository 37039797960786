import { createStore } from 'vuex'
import { orderBy } from 'lodash'
export default createStore({
  state: {
    downloadsRelatoriosNfs: [],
    downloadsRelatorioEstoque: [],
    relatorioNfsLoading: false,
    relatorioEstoqueLoading: false,
    msgRelatorioVazio:null
  },
  getters: {
  },
  mutations: {
    adicionaDownloads(state, { download, downloadListName }) {
      state[downloadListName].push(download)
    },

    listaDownloads(state, { downloads, downloadListName}) {
      if(downloads.every(item=> item.data_criacao)){
        downloads = orderBy(downloads, ['data_criacao'], ['asc'])
      }
      state[downloadListName] = downloads
    },

    toggleLoading(state, { loading, name }) {
      state[name] = loading
    },
    relatiorioVazio(state, payload){
      state.relatorioEstoqueLoading = false
      state.relatorioNfsLoading = false
      state.msgRelatorioVazio = payload
    }
  },
  actions: {
    addDownload({ commit }, { download, downloadListName }) {
      commit("adicionaDownloads", { download, downloadListName })
    },

    insertDownloads({ commit }, downloads) {
      commit("listaDownloads", downloads)
    },

    changeLoading({ commit }, { loading, name }) {
      commit("toggleLoading", { loading, name })
    },
    addMsgRelatorioVazio({commit}, payload){
      commit('relatiorioVazio', payload)
    }
  },
  modules: {
  }
})
