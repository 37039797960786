export const bufferDownloadPlugin = {
  install(app, options) {
    app.config.globalProperties.$bufferDownload = bufferDownload
  }
}

const bufferDownload = ({ buffer, downloadName }) => {
  const objectURL = URL.createObjectURL(buffer)
  const linkEl = document.createElement('a')

  linkEl.href = objectURL
  linkEl.download = downloadName
  linkEl.click()
}