<script>
import { setUser } from '../../services/userService'

export default {
  name: "Auth",
  methods: {
    verifyToken() {
      const { auth_token } = this.$route.params

      if(auth_token) {
        this.$api(auth_token).get("/current_pessoa").then(
          res => {
            setUser(res.data)
            window.localStorage.setItem("auth_token", auth_token);
            this.$router.push("/");
          },
        ).catch( err => {
          this.$router.push("/unauthorized")
        });
      }
    }
  },

  mounted() {
    this.verifyToken()
  }
}
</script>