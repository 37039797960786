import { api } from "../services/api"
import { initializeAbly } from "../services/ably"

export const ablyPlugin = {
	install(app, options) {
    app.config.globalProperties.$initAbly = initializeAbly
	}
}

export const apiPlugin = {
  install(app, options) {
    app.config.globalProperties.$api = api
  }
}

String.prototype.formatDate = function() {
  return this.split("-").reverse().join("/")
}

String.prototype.getDayAndMonth = function() {
  const date = new Date(Date.parse(this)).toLocaleDateString("pt-BR", { timeZone: "UTC" })

  return date.slice(0, 5)
}

String.prototype.getOnlyDay = function() {
  let dateString = new Date(this + ' ')
  return String(dateString.getDate()).padStart(2, '0')
}

Date.prototype.addDays = function(days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}
