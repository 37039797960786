import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueSelect from 'vue-select';
import FlashMessage from './components/shared/FlashMessage';
import { ablyPlugin, apiPlugin } from './plugins/index.js';
import { moneyMaskPlugin } from './plugins/moneyMask.js';
import { bufferDownloadPlugin } from './plugins/bufferDownload.js';
import { currentMonthPlugin } from "./plugins/currentMonth";
import { numberHelperPlugin } from "./plugins/numberHelper";
import 'vue-select/dist/vue-select.css';
import './assets/stylesheets/global.scss';

const app = createApp(App)
  .component('vue-select', VueSelect)
  .component('flash-message', FlashMessage)
  .use(store)
  .use(router)
  .use(ablyPlugin, {})
  .use(apiPlugin)
  .use(moneyMaskPlugin)
  .use(bufferDownloadPlugin)
  .use(currentMonthPlugin)
  .use(numberHelperPlugin)
  .mount('#app')

window.addEventListener("message", (ev)=> {
  if (ev.data == "login-expirado") {
    app.$router.push({ name: "ErroAutenticacao" })
  }
});